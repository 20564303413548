<template>
  <base-layout>
    <div class="trading-section bg-trading1">
      <div class="row text-center">
        <div class="col-sm-12">
          <div class="heading">
            <img
              :src="require('@/assets/img/trading-heading1.png')"
              style="width:90%;height:auto"
            />
          </div>
        </div>
        <div class="col-lg-12 col-xs-12">
          <div class="section-content">
            <h3 class="text-sub">
              THE PROGRAM WILL TAKE PLACE FROM SEPTEMBER 1 TO SEPTEMBER 30 <br />DATA RANKING BOARD
              WILL BE UPDATED <span class="text-color-yellow">AT 0:00 EVERY SUNDAY (UTC + 0)</span>
            </h3>
            <div class="box">
              <div class="table-responsive" style="max-height: 400px;">
                <table
                  class="table table-vcenter table-bordered table-hover table-striped table-condensed"
                >
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>ID</th>
                      <th>IB IN Month</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="list in ListTopLeader" :key="list.id">
                      <td style="color:#0CA300">
                        <img
                          :src="require(`@/assets/img/lv-${list.id}.png`)"
                          v-if="list.id <= 3"
                          width="40"
                        />
                        <span v-else>{{ list.id }}</span>
                      </td>
                      <td>
                        <span v-if="list.id <= 3"
                          ><b>{{ list.userID }}</b></span
                        >
                        <span v-else>{{ list.userID }}</span>
                      </td>
                      <td>
                        <span v-if="list.id <= 3"
                          ><b>{{ list.ib }}</b></span
                        >
                        <span v-else>{{ list.ib }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="trading-section bg-trading2">
      <div class="row text-center">
        <!-- <div class="col-sm-12">
                <div class="heading">
                    <span class="img-light">
                        <img class="top" :src="require('@/assets/img/light.png')">
                        <img class="bottom" :src="require('@/assets/img/light.png')">
                    </span>
                    <img :src="require('@/assets/img/trading-heading2.png')">
                </div>
            </div> -->
        <div class="col-lg-12 col-xs-12">
          <div class="section-content">
            <h2 class="title-sub">PERSONAL INFORMATION</h2>
            <h3 class="text-sub">
              PERSONAL DATA WILL BE UPDATED EVERY DAY
              <span class="text-color-yellow">AT 0:00 (UTC + 0)</span>
            </h3>
            <div class="box">
              <div class="table-responsive box2" style="max-height: 400px;">
                <table
                  class="table table-vcenter table-bordered table-hover table-striped table-condensed"
                >
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>ID</th>
                      <th>IB IN Month</th>
                      <th>volume IN Month</th>
                      <th>PARTICIPATION STANDARDS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="color:#0CA300">01</td>
                      <td>{{ InfoTopLeader.userID }}</td>
                      <td>${{ InfoTopLeader.ib }}</td>
                      <td>${{ InfoTopLeader.volume }}</td>
                      <td>
                        <input
                          type="checkbox"
                          id="cb1"
                          v-if="InfoTopLeader.status == 1"
                          checked
                          readonly
                        />
                        <input
                          type=""
                          v-else
                          readonly
                          style="width: 18px;height: 18px;border: 2px #007308 solid;"
                        />
                        <label for="cb1"></label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="box-desc">
                <h3>CONDITIONS FOR PARTICIPATING IN THE PROGRAM:</h3>
                <ul>
                  <li>
                    <i class="fa fa-circle" aria-hidden="true"></i> Agency must hold
                    <span class="text-color-yellow">0.8% IB</span> - Royale level. (requires 1 week
                    to reach this milestone)
                  </li>

                  <li>
                    <i class="fa fa-circle" aria-hidden="true"></i>Total number of IBs reached per
                    month at least <span class="text-color-yellow">$10,000</span>
                  </li>

                  <li>
                    <i class="fa fa-circle" aria-hidden="true"></i>Personal transaction volume/month
                    must reach <span class="text-color-yellow">$10.000</span>
                  </li>

                  <li>
                    <i class="fa fa-circle" aria-hidden="true"></i>The system has at least
                    <span class="text-color-yellow">7 active</span> members/week (transactions over
                    <span class="text-color-yellow">$200</span>) (Qualified players will be ticked
                    in the box for participation criteria)
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <span class="line-bottom"></span>
          <span class="line-bottom"></span>
          <span class="line-bottom"></span>
          <span class="line-bottom"></span>
          <span class="line-bottom"></span>
          <span class="line-bottom"></span>
        </div>
      </div>
    </div>
    <div class="trading-section bg-trading3">
      <div class="row text-center">
        <div class="col-sm-12">
          <div class="heading">
            <span class="img-light">
              <img class="top" :src="require('@/assets/img/light.png')" />
              <img class="bottom" :src="require('@/assets/img/light.png')" />
            </span>
            <img :src="require('@/assets/img/trading-heading3.png')" />
          </div>
        </div>
        <div class="col-lg-12 col-xs-12">
          <div class="section-content">
            <div class="box-title">
              <span class="light">
                <img class="bottom" :src="require('@/assets/img/light.png')" />
              </span>
              <span>
                Time:<br />
                <span class="text-color-yellow" style="font-weight:800">FROM 1/9 TO 30/9</span>
              </span>
            </div>
            <div class="box-desc" style="width:100%;max-width:800px;">
              <h3 class="text-white">CONDITIONS FOR PARTICIPATING IN THE PROGRAM:</h3>
              <ul style="text-transform:uppercase;">
                <li style="font-weight:800">
                  <i class="fa fa-circle" aria-hidden="true"></i> Agency must hold
                  <span class="text-color-yellow" style="font-weight:900">0.8% IB</span> - Royale
                  level. (requires 1 week to reach this milestone)
                </li>

                <li style="font-weight:800">
                  <i class="fa fa-circle" aria-hidden="true"></i>Total number of IBs reached per
                  month at least
                  <span class="text-color-yellow" style="font-weight:900">$10,000</span>
                </li>

                <li style="font-weight:800">
                  <i class="fa fa-circle" aria-hidden="true"></i>Personal transaction volume/month
                  must reach <span class="text-color-yellow" style="font-weight:900">$10.000</span>
                </li>

                <li style="font-weight:800">
                  <i class="fa fa-circle" aria-hidden="true"></i>The system has at least
                  <span class="text-color-yellow" style="font-weight:900">7 active</span>
                  members/week (transactions over <span class="text-color-yellow">$200</span>)
                  (Qualified players will be ticked in the box for participation criteria)
                </li>
              </ul>
              <h3 class="text-white">
                PRIZES WILL BE SUMMED UP AND AWARDED ON
                <span class="text-color-yellow" style="font-weight:900">OCTOBER 5</span>
              </h3>
            </div>
          </div>
          <div class="btn-reward">
            <img class="img-reward" :src="require('@/assets/img/img-reward.png')" />
            <button @click="modalShowReward()">
              <img :src="require('@/assets/img/btn-reward.png')" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <v-modal
      name="showReward"
      :width="'100%'"
      :height="'auto'"
      :adaptive="true"
      :styles="
        'overflow-y: auto;max-width:650px;max-height:85vh;right:0;margin:auto;background:#fff0!important;'
      "
    >
      <!-- <div class="title">
            <button @click="HideModalNoti()" class="btn-close">
            <i class="fa fa-times"></i>
            </button>
        </div> -->
      <div class="modal-content" style="background:#fff0!important;box-shadow:none;border:none">
        <img width="100%" :src="require('@/assets/img/modal-reward.png')" />
      </div>
    </v-modal>
  </base-layout>
</template>

<script>
import BaseLayout from "./layouts/Base";
import { mapGetters } from "vuex";
export default {
  components: { BaseLayout },
  data: () => ({
    passwordCurrent: ""
  }),
  computed: {
    ...mapGetters({
      InfoTopLeader: "topleader/InfoTopLeader",
      ListTopLeader: "topleader/ListTopLeader"
    })
  },
  mounted() {},
  created() {
    this.$store.dispatch("topleader/req_getInfoTopLeader");
    this.$store.dispatch("topleader/req_getListTopLeader");
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    modalShowReward() {
      this.$modal.show("showReward");
    },
    HideModalNoti() {
      this.$modal.hide("showReward");
    }
  }
};
</script>

<style scoped>
input[id="cb1"] + label {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid #007308;
  border-radius: 2px;
  cursor: pointer;
  margin: 5px 0 0;
  text-align: center;
}
input[id="cb1"]:checked + label:after {
  position: relative;
  top: -4px;
  left: 0px;
  content: "\2714";
  font-size: 14px;
}
input[id="cb1"] {
  display: none;
}
.title {
  text-align: right;
  background: #fff0;
  padding: 0;
  font-size: 15px;
  font-weight: 700;
  color: #007308;
}
#page-content {
  padding: 0 !important;
  position: relative;
}
.btn-reward {
  margin: auto;
  max-width: 350px;
  position: relative;
  text-align: center;
}
.vm--modal {
  background: #fff0;
  padding: 0;
  max-width: 600px !important;
}
.img-reward {
  width: 100%;
}
.btn-reward button {
  position: absolute;
  background: #fff0;
  outline: none;
  border: none;
  box-shadow: none;
  padding: 0;
  left: 0;
  right: 0;
  top: 12%;
  width: 100%;
  margin: auto;
}
.btn-reward button img {
  width: 180px;
}
.box-title {
  width: fit-content;
  margin: auto;
  padding: 5px 25px;
  border: 2px #007308 solid;
  border-radius: 20px;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  font-style: italic;
  position: relative;
}

.box-title span {
  position: relative;
  z-index: 2;
}
.box-title .light {
  position: absolute;
  width: 120%;
  bottom: 0px;
  left: -15%;
  z-index: 1;
}
.box-title .light img {
  width: 100%;
}
.table-responsive {
  margin: 0;
}
.text-white {
  color: #fff !important;
}
.line-bottom {
  display: inline-block;
  margin: 0 2px 30px;
  height: 2px;
  width: 30px;
  background: rgb(255 255 255 / 0.7);
}
.section-content {
  padding: 40px 0 25px;
}
.section-content .title-sub {
  font-size: 20px;
  font-weight: 900;
  color: #fff;
  line-height: 1.4;
  max-width: 700px;
  margin: 0 auto 20px;
}
.section-content .text-sub {
  font-size: 18px;
  font-weight: 500;
  font-style: italic;
  color: #fff;
  line-height: 1.35;
  max-width: 700px;
  margin: auto;
}
.box-desc {
  width: 80%;
  max-width: 600px;
  margin: 25px auto 0;
  text-align: left;
}
.box-desc h3 {
  color: transparent;
  background: linear-gradient(to right, #007308, #77b608);
  font-family: "Orbitron", sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 900;
  text-transform: uppercase;
  -webkit-background-clip: text;
  -webkit-text-stroke: 0.5px white;
  filter: drop-shadow(0 0 3px rgb(0, 0, 0, 0.3));
}
.box-desc ul {
  list-style: none;
  margin-bottom: 0;
}
.box-desc ul li {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 10px;
}
.box-desc ul li i {
  color: #fffa94;
  font-size: 12px;
  padding-right: 10px;
}
.section-content .box {
  border: 2px #77b61d solid;
  border-radius: 10px;
  max-width: 900px;
  font-family: sans-serif;
  margin: auto;
  color: #fff;
  margin-top: 30px;
  background: rgb(0 0 0 / 0.2);
  padding: 30px;
}
.section-content .box .box2 {
  border: 2px #fff solid;
  border-radius: 10px;
  max-width: 800px;
  font-family: sans-serif;
  margin: auto;
  color: #fff;
  background: #282627;
  margin: 0px 15px;
  padding: 15px 30px;
}
.table-responsive {
  max-height: 500px;
  overflow: auto;
}
.text-color-yellow {
  color: #fffa94 !important;
  font-weight: 600;
}
.section-content .box .table thead > tr > th,
.section-content .box .table tbody > tr > th,
.section-content .box .table tfoot > tr > th,
.section-content .box .table thead > tr > td,
.section-content .box .table tbody > tr > td,
.section-content .box .table tfoot > tr > td,
.section-content .box .table tbody + tbody,
.section-content .box .table-bordered,
.section-content .box .table-bordered > thead > tr > th,
.section-content .box .table-bordered > tbody > tr > th,
.section-content .box .table-bordered > tfoot > tr > th,
.section-content .box .table-bordered > thead > tr > td,
.section-content .box .table-bordered > tbody > tr > td,
.section-content .box .table-bordered > tfoot > tr > td {
  border: 2px #fff0 solid !important;
  margin: 2px !important;
}
.trading-section .heading {
  max-width: 600px;
  margin: auto;
  position: relative;
  text-align: center;
}
.trading-section .heading .img-light img {
  position: absolute;
  width: 90%;
  min-width: 70px;
}
.trading-section .heading .img-light img.bottom {
  bottom: -55%;
  left: -15%;
  z-index: 1;
}
.trading-section .heading .img-light img.top {
  top: -55%;
  right: -15%;
  z-index: 1;
}
.trading-section .heading img {
  width: auto;
  position: relative;
  z-index: 2;
  height: 25px;
}
.section-content .box .table {
  border-collapse: inherit;
  border-spacing: 3px;
  color: #fff;
}
.section-content .box .box2 .table {
  border-spacing: 7px;
}
.section-content .box .table thead > tr > th {
  background: linear-gradient(to top, #007308, #77b608);
  padding: 10px 15px;
  color: #fff;
  text-align: center;
  font-weight: 900;
  font-size: 15px;
  text-transform: uppercase;
}
.section-content .box .table-striped > tbody > tr {
  background: #e8d4db;
  color: #333333;
  font-size: 15px;
  font-weight: 500;
}
.section-content .box .table-striped > tbody > tr:nth-of-type(odd) {
  background: #fff;
  color: #333333;
  font-size: 15px;
  font-weight: 500;
}
.trading-section {
  background-position: center center;
  background-size: cover;
  margin: -20px -20px -1px;
  padding: 60px 20px 30px;
  background-repeat: no-repeat;
}
.section-content .box .box2 .table th,
.section-content .box .box2 .table td {
  width: 20%;
}
.bg-trading1 {
  background-image: url("../assets/img/bg_trading_secion1-min.png");
}
.bg-trading2 {
  background-image: url("../assets/img/bg_trading_secion2-min.png");
}
.bg-trading3 {
  background-image: url("../assets/img/bg_trading_secion3-min.png");
}
.text-center {
  text-align: center !important;
}
.btn-group > .btn {
  float: none;
}
.justify-content-center {
  justify-content: center !important;
}
.font-medium,
.text-theme-11 {
  text-align: left;
  color: #fff;
  padding: 0 15px;
  margin-bottom: 0px;
}
.text-theme-11 {
  color: yellow;
}
.upload_images {
  width: calc(100% - 30px);
  height: 160px;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
  background: #daf2fdb3 !important;
  border-radius: 10px;
  margin: 0 15px;
  margin-bottom: 2rem;
  margin-top: 0rem;
}
.widget {
  background-color: rgb(255 255 255 / 0.25);
}
.show_images {
  width: calc(100% - 30px);
  height: 200px;
  position: relative;
  padding: 20px;
  margin: 0 15px;
  text-align: center;
  background: #daf2fdb3 !important;
}

.show_images img {
  height: 100%;
  width: auto;
  display: inline-block;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.show_images button {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 35px;
  background: gold;
  border: none;
  right: 0;
  padding: 5px 15px;
  bottom: 0;
  margin: auto;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.show_images:hover {
  background: #056996b3 !important;
}

.show_images:hover img {
  opacity: 0.5;
}

.show_images:hover button {
  opacity: 1;
}

.upload_file_1 {
  height: 0;
  overflow: hidden;
  width: 0;
}

.upload_file_1 + label {
  background: #f15d22;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: "Rubik", sans-serif;
  font-size: inherit;
  font-weight: 500;
  outline: none;
  padding: 10px 49px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  justify-content: center;
  align-self: center;
  align-items: center;
  align-content: center;
  width: max-content;
}

@media screen and (max-width: 575px) {
  .box-desc ul {
    padding-left: 0;
  }
}
.upload_file_1 + label.btn-2 {
  background-color: #f78b00;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 3px 4px 10px -4px rgb(0 0 0 / 0.7);
}
.upload_file_1 + label.btn-2::before {
  color: #fff;
  content: "\f382";
  font-family: "Font Awesome 5 Pro";
  font-size: 100%;
  height: 100%;
  right: 130%;
  line-height: 3.3;
  position: absolute;
  top: 0px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.upload_file_1 + label.btn-2:hover {
  background-color: #fbc500;
}
.upload_file_1 + label.btn-2:hover::before {
  right: 75%;
}
.icon {
  width: 80px;
  height: 80px;
  margin: auto;
  background: #c8973b;
  line-height: 80px;
  font-size: 36px;
  border-radius: 50%;
  color: #fff;
}
.mr-5 {
  margin-right: 5px !important;
}
.vm--modal {
  overflow-y: auto;
}
.profile h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}
.bg-theme-11 {
  margin: 0;
  height: 47px;
  display: block;
  line-height: 0;
  padding: 10px;
  background: #3ab32a;
  color: #fff;
}
.title {
  text-align: center;
  background: #1e90ff;
  padding: 5px 0;
  font-size: 15px;
  font-weight: 700;
  color: white;
}
.col-4 {
  width: 33.33333333%;
  float: left;
}
.col-8 {
  width: 66.6666667%;
  float: left;
}
</style>
